import React, { useState, useEffect } from "react";
import { useHistory, Link } from 'react-router-dom'
import { Carousel } from 'react-responsive-carousel';
import { useActiveWeb3React } from "../web3";
import { fetchApi } from '../utils/fetchApi'
import { ExhibitionHallCard } from "../components/ExhibitionHall";
import { ArtistCard } from "./Artist/ArtistCard.js";
import { getChainType, getNFT721Address } from "../web3/address";
import { getAuctionPool, get721AuctionPool } from "./useContract";
import { usePools } from "./Auction/Hooks";
import { useArtistList } from "./Artist/Hooks";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import Banner1 from '../assets/img/home/banner1.png';
import Banner2 from '../assets/img/home/banner2.png';
import Banner3 from '../assets/img/home/banner3.png';
import Banner4 from '../assets/img/home/banner4.png';
import titleBefore from "../assets/img/home/title.png";
import { useTranslation } from "react-i18next";


export const HomePage = () => {
    const {active, library, chainId, account} = useActiveWeb3React()
    const [list, setList] = useState([]);
    const history = useHistory()
    const { pools } = usePools(-1, -1)
    const { artistList } = useArtistList(1, 4);
    const chainType = getChainType(chainId)
    const { t } = useTranslation();
    
    const fetch = (hasAccount) => {
        fetchApi(`tokens?pageNo=1&pageSize=4`, {}, library, chainId, hasAccount).then(({ tokens }) => {
            tokens = tokens.filter(token => token.chainType == chainType).slice(0, 4);
            if (tokens && tokens.length > 0) {
                tokens.forEach(async (token, index) => {
                    tokens[index]['pool'] = await fetchPool(token.tokenId, token.tokenType);
                })
                console.log('newList', tokens);   
            }
            setList(tokens)
        })
    }

    const fetchPool = async (tokenId, tokenType) => {
        if (tokenType === 'ERC721' && library) {
            console.log('token---3:', tokenId);
            const poolid = await get721AuctionPool(tokenId, library, chainId)
            const AuctionPool = await getAuctionPool(poolid, tokenId, account, library, chainId)
            return AuctionPool
        } else {
            return undefined
        }
    }


    useEffect(() => {
        fetch(active)
    }, [active, library])

    return (
        <div className="home">
            <aside id="banner">
                <Carousel showStatus={false} showThumbs={false} autoPlay infiniteLoop>
                    <div>
                        <img src={Banner1} />
                    </div>
                    <div>
                        <img src={Banner2} />
                    </div>
                    <div>
                        <img src={Banner3} onClick={() => { history.push('/drop') }}/>
                    </div>
                    <div>
                        <img src={Banner4} />
                    </div>
                </Carousel>
            </aside>
            <h1>{t('new')}
                <Link className="link" to="/marketplace">{t('view_all')}</Link>
            </h1>
            <hr/>
            <div className="exhibition-hall-list">
                {list && list.slice(0, 4).map(item => (
                    <ExhibitionHallCard
                        key={item.tokenId}
                        item={item}
                    />
                ))}
            </div>
            <h1>{t('artist')}
                <Link className="link" to="/creators">{t('view_all')}</Link>
            </h1>
            <hr />
            <div className="exhibition-hall-list">
                {artistList && artistList.slice(0, 4).map(item => (
                    <ArtistCard
                        key={item.tokenId}
                        item={item}
                    />
                ))}
            </div>
        </div>
    )
};
