import React, {useState} from "react";
import { Modal, Button, Row, Col, Form, Input, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined, InboxOutlined } from '@ant-design/icons';


const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const { Dragger } = Upload;
const { TextArea } = Input;


function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

export const ArtistProfileModal = ({ type, visible, closeModal, artistInfo }) => { 
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const handleOk = () => {
      setConfirmLoading(true);
      setTimeout(() => {
        closeModal();
        setConfirmLoading(false);
      }, 2000);
    };
  
    const handleCancel = () => {
      closeModal();
    };
  
    const onFinish = (values) => {
      console.log('Success:', values);
    };
  
    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };
  
  
    const handleChange = info => {
      if (info.file.status === 'uploading') {
        setLoading(true);
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, url => { 
          setImageUrl(url);
          setLoading(false);
        });
      }
      debugger
    };
  
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
  
    const props = {
      name: 'file',
      multiple: true,
      // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
        if (status === 'done') {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };    
  
    return (
        <Modal
          title={`${type='eidt'? 'Edit Profile':'Create Artist'}`}
          visible={visible}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            name="artist-profile"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            requiredMark={false}
          >
            <Row>
              <Col xs={24} sm={8}>
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  // action=""
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                >
                  {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload>
                <p>File type supported: JPG, PNG, GIF</p>
                <Button type="default" >Edit</Button>
              </Col>
              <Col xs={24} sm={16}>
                <Form.Item
                  name="name"
                  initialValue={artistInfo.name}
                  rules={[{ required: true, message: 'Please input name!' }]}
                >
                  <Input className="name-input" placeholder="Name"/>
                </Form.Item>

                <Form.Item
                  label="WebSite"
                  name="website"
                  initialValue={artistInfo.website}
                  rules={[{ required: true, message: 'Please input website!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Instagram"
                  name="instagram"
                  initialValue={artistInfo.instagram}
                  rules={[{ required: true, message: 'Please input instagram!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Twitter"
                  name="twitter"
                  initialValue={artistInfo.twitter}
                  rules={[{ required: true, message: 'Please input twitter!' }]}
                >
                  <Input />
                </Form.Item>

              </Col>
            </Row>
            <Form.Item
              label="Short about"
              name="short"
              initialValue={artistInfo.bio}
              rules={[{ required: true, message: 'Please input short about!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="about"
              initialValue={artistInfo.aboutMe}
              rules={[{ required: true, message: 'Please input about!' }]}
            >
              <TextArea rows={4} className="about-textarea" placeholder="About"/>
          </Form.Item>
          <Form.Item>
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                band files
              </p>
            </Dragger>
          </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Save Changes
              </Button>
            </Form.Item>
          </Form>
        </Modal>
    )
}
