import {getContract} from "../web3";
import ERC721 from "../web3/abi/ERC721.json";
import {getGalleryNFTAddress, getNFTTokenAddress} from "../web3/address";

import env from '../constants/env';

export async function fetchApi(url, params, library, chainId, hasAccount) {
  var resp = await fetch(env.API_URL + url, params)
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  let cards = await resp.json()
  let contract
  if (library) {
    contract = getContract(library, ERC721.abi, getNFTTokenAddress(chainId))
  }

  if (!cards.tokens) {
    if (hasAccount && library) {
       cards.real_owner = await contract.methods.ownerOf(cards.tokenId).call()
    }
    return cards
  }
  cards.tokens = await Promise.all(cards.tokens.map(async item => {
    const card = item
    if (hasAccount && library) {
      card.real_owner = await contract.methods.ownerOf(item.tokenId).call()
    }
    return card
  }))
  return cards
}
