import React from "react";
import { Link } from "react-router-dom";
import {getPoolLeftTime} from "../../utils/time";
import {formatAmount} from "../../utils/format";
import { useTranslation } from "react-i18next";

export const ArtistCard = ({ item }) => {
    const { t } = useTranslation()
    const href = `/creators/${item.address}`
    const left = item.pool ? getPoolLeftTime(item.pool.closeAt) : {};
    return (
        <div className="artist_item">
            <Link to={href} className="image">
                <img src={item.avatar+"?t=1"} alt="avatar" />
            </Link>
            <div className="content">
                <Link to={href} className="title h4">
                    {item.name}
                </Link>
                <p>{t('creator')}</p>
            </div>
        </div>
    );
};
