import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Tag, Modal } from 'antd';
import {fetchApi} from '../../utils/fetchApi'
import { BackButton } from "../../components/BackButton";
import ERC721 from "../../web3/abi/ERC721.json";
import { getContract, useActiveWeb3React } from "../../web3";
import { getNFT721Address, getTokenContracts, getNFTAddress } from "../../web3/address";
import EnglishAuctionNFT from "../../web3/abi/EnglishAuctionNFT.json";
import {
  getEnglishAuctionNFTAddress,
  getNFTTokenAddress
} from "../../web3/address";
import { getAuctionPool, get721AuctionPool, get721SwapPool, get1155AuctionPool, get1155SwapPool} from "../useContract";
import { CreatePool } from "../Auction/AccountRoutes";
import {formatAmount, formatAddress} from "../../utils/format";
import { getPoolLeftTime } from "../../utils/time";
import { SellNFTModal } from "./SellNFTModal";
import { AuctionDetailsModal } from "../../components/Modals";
// import Artist1 from '../../assets/img/artist/artist1.png';

export const ExhibitionHallCardPage = (props) => {
    const {tokenId, tokenType } = useParams();
    const {active, account, library, chainId} = useActiveWeb3React()
    const [token, setToken] = useState({})
    const [auctionInfo, setAuctionInfo] = useState()
    const [contracts, setContracts] = useState({})
    const [left, setLeft] = useState()
    const [isOpen, setIsOpen] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showSellNFTModal, setShowSellNFTModal] = useState(false);
    const { t } = useTranslation();

    console.log('chainId:', chainId);

    const fetchTokens = async (hasAccount) =>{
        var token = await fetchApi(`/tokens/${chainId}/${tokenType}/${tokenId}`, {
            method: 'GET'
        }, library, chainId, hasAccount)
        console.log('token123:', token);
        const contract = getContract(library, ERC721.abi, getNFTTokenAddress(chainId))
        if (library)
            token.real_owner = await contract.methods.ownerOf(tokenId).call()
        setToken(token)
    }

    const fetchPool = async (library) => {
        if (tokenType === 'ERC721' && library) {
            const poolid = await get721AuctionPool(tokenId, library, chainId)
            console.log('aaa poolid', poolid)
            const nft721 = getNFT721Address(chainId)
            const AuctionPool = await getAuctionPool(poolid, tokenId, account, library, chainId)
            console.log('AuctionPool:', AuctionPool);
            setAuctionInfo(AuctionPool)
        }
    }

    useEffect(() => { 
        if (tokenType === 'ERC721' && library) {
            fetchPool(library)
        }
    }, [tokenType, library])


    useEffect(() => {
        setContracts(getTokenContracts())
    }, [])

    useEffect(() => {
        if (chainId) { 
            fetchTokens(active)
        }
    }, [active, chainId])

    let timer
    useEffect(() => {
        if (auctionInfo) {
            const date = new Date(auctionInfo.closedAt);
            const now = new Date();
            const lefttime = (date - now);
            if (lefttime > 0) {
                timer = setInterval(() => {
                    const l = getPoolLeftTime(auctionInfo.closeAt)
                    console.log('left:', l);
                    setLeft(l)
                }, 1000)   
            }
        }
        return () => {
            console.log("clear interval")
            clearInterval(timer)
        }
   }, [auctionInfo])

    return (
        <div>
            {/* {showCreateModal && <div className="modal-show">
                <div className="wrapper">
                    <div className="modal">
                        <div className="modal__box">
                            <CreatePool onCancel={()=>{setShowCreateModal(false)}}/>
                        </div>
                    </div>
                </div>
            </div>} */}
            <SellNFTModal visible={showSellNFTModal} closeModal={() => { setShowSellNFTModal(false) }} auctionInfo={auctionInfo} token={token}/>
            <Modal className="img-preview" onCancel={() => {setIsModalVisible(false)}} visible={isModalVisible} footer={null}>
                {token.fullimage && (token.fullimage.includes('.gif') || token.fullimage.includes('.png') || token.fullimage.includes('.jpg') || token.fullimage.includes('.jpeg'))
                    && <img src={token.fullimage} />
                }
                {token.fullimage && (token.fullimage.includes('.mp4') || token.fullimage.includes('.mov'))
                    &&
                    <video controls>
                        <source src={token.fullimage}/>
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                    }
            </Modal>
            <h3 className="exhibition-hall-card__header__author">
                <span>{token.name}</span>
            </h3>
            <div className="exhibition-hall-card">
                <div className="exhibition-hall-card__left">
                    <div className="exhibition-hall-card__box">
                        {token.fullimage && (token.fullimage.includes('.gif') || token.fullimage.includes('.png') || token.fullimage.includes('.jpg') || token.fullimage.includes('.jpeg'))
                            && <img src={token.fullimage} onClick={() => {setIsModalVisible(true)}}/>
                        }
                        {token.fullimage && (token.fullimage.includes('.mp4') || token.fullimage.includes('.mov'))
                            && 
                            <video controls>
                                <source src={token.fullimage}/>
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                            }  
                    </div>    
                </div>
                <div className="exhibition-hall-card__right">
                    <div className="exhibition-hall-card__box">
                        <div className="owner">
                            <div className="info">
                                <div>
                                    <div className="img">
                                        {token.artistAvatar && <img src={token.artistAvatar} />}
                                    </div>
                                    <div className="name">
                                        <p>{t('creator')}</p>
                                        <h2>{token.artist}</h2>
                                    </div>
                                </div>
                                <div>
                                    {/* <Tag>1/10</Tag>
                                    <Tag>#Video</Tag> */}
                                </div>
                            </div>
                        </div>
                    </div>    
                    <div className="exhibition-hall-card__box">
                        {
                            auctionInfo &&
                            <div className="auction">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>
                                                {auctionInfo.status === 'live' && auctionInfo.type === 'bid' && 'Current Price'}
                                                {auctionInfo.status === 'live' && auctionInfo.type === 'swap' && 'Price'}
                                                {auctionInfo.status === 'closed' && auctionInfo.type === 'bid' && auctionInfo.bidder && t('sold_for')}
                                                {auctionInfo.status === 'closed' && auctionInfo.type === 'bid' && !auctionInfo.bidder && 'Starting Price'}
                                                {auctionInfo.status === 'closed' && auctionInfo.type === 'swap' && t('sold_for')}
                                            </th>
                                            <th colspan="2">{left && `Ending In`}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{formatAmount(auctionInfo.LastPrice)}&nbsp;ETH</td>
                                            <td colspan="2">{left && `${left.days}d  ${left.hours} : ${left.minutes} : ${left.seconds}`}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                { auctionInfo.status === 'closed' && <h3 className="not_sale"> { t('item_not_for_sale') }</h3>}
                                {
                                    token.real_owner === account && 
                                    <button type="button" className="btn" onClick={() => { setShowSellNFTModal(true)}}>
                                        Sell
                                    </button>
                                }
                                {/* <button type="button" className="btn" onClick={() => { setIsOpen(true) }}>
                                    {auctionInfo.status === 'closed' ? 'Closed' : t('join_the_auction')}
                                </button>
                                {isOpen && (
                                    <div className="modal-show">
                                        <div className="wrapper">
                                            <AuctionDetailsModal item={auctionInfo} setIsOpen={setIsOpen} />
                                        </div>
                                    </div>
                                )} */}
                            </div>   
                        }
                        {
                            !auctionInfo &&
                            <div className="auction">
                                <h3 className="not_sale">{t('item_not_for_sale')}</h3>
                                {token.real_owner === account && <button type="button" className="btn" onClick={()=>{setShowCreateModal(true)}} >{t('list_for_sale')}</button>}
                            </div>
                        }
                    </div>    
                </div>
            </div>
            <div className="exhibition-hall-card">
                <div className="exhibition-hall-card__left">
                    <div className="exhibition-hall-card__box">
                        <div className="title">
                            {t('description')}
                        </div>
                        <div className="content">
                            {token.description &&
                                <p>{token.description}</p>
                            }
                        </div>    
                    </div>    
                </div>
                <div className="exhibition-hall-card__right">
                    <div className="exhibition-hall-card__box">
                        <div className="title">
                            {t('on_chain_info')}
                        </div>
                        <div className="content">
                            <table className="exhibition-hall-card__table">
                                <tbody>
                                    { token.real_owner && 
                                    <tr>
                                        <td>{t('owner')}</td>
                                        <td  className="break-all">
                                            {formatAddress(token.real_owner)}
                                        </td>
                                    </tr>
                                    }
                                    <tr>
                                        <td>{t('contract_address')}</td>
                                        <td> <a href={getNFTAddress(chainId)} target="_blank">{contracts[getNFTTokenAddress(chainId)]}</a></td>
                                    </tr>
                                    <tr>
                                        <td>{t('token_id')}</td>
                                        <td>{token.tokenId}</td>
                                    </tr>
                                    <tr>
                                        <td>{t('assets_protocol')}</td>
                                        <td>1 of 1 (ERC721)</td>
                                    </tr>
                                    <tr>
                                        <td>{t('blockchain')}</td>
                                        <td>Ethereum</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    );
};
