// declare var process;
// declare var module;
// declare var require;

/* tslint:disable */
const development = require('./development.json');
const production = require('./production.json');

const env = { development, production }[process.env.DEPLOY_ENV || 'development']

export default env;
