import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import {Web3ReactProvider} from "@web3-react/core";
import {Web3Provider} from "@ethersproject/providers";
import './i18n'
import {Header} from "./components/header/Header";
import {AboutPage} from "./pages/AboutPage";
import {HomePage} from "./pages/HomePage";
import {ContextProvider} from "./reducer";
import {InitPage} from "./pages/InitPage";
import { AuctionPage } from "./pages/Auction";
import ComingSoon from './pages/ComingSoon';
import { LandingPage } from './pages/LandingPage';
import {
  ExhibitionHallListPage,
  ExhibitionHallPublishPage,
  ExhibitionHallCardPage,
} from "./pages/ExhibitionHall";
import { ArtistListPage, ArtistDetailPage } from './pages/Artist';
import {Footer} from "./components/Footer";
import 'antd/dist/antd.css';
import "./assets/css/style.scss";
function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 8000;
  return library;
}

function App() {
  const [locale, setLocale] = useState("en")
  const [loadingState, setLoadingState] = useState(true)

  return (
      <>
      {loadingState ? (
      <ContextProvider>
      <Router>
        <LandingPage setLoadingState={setLoadingState} />
      </Router>
    </ContextProvider>
        ) : (
      <ContextProvider>
        <Web3ReactProvider getLibrary={getLibrary}>
        <Router>
          <Header />
          <Switch>
            <Route path="/about">
              <AboutPage />
            </Route>
            <Route path="/coming-soon">
              <ComingSoon/>
            </Route>
          <div id="container">
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route
              exact
              path="/creators"
              component={ArtistListPage}
            />
            <Route
              exact
              path="/creators/:address"
              component={ArtistDetailPage}
            />
            <Route
              exact
              path="/marketplace"
              component={ExhibitionHallListPage}
            />
            <Route
              exact
              path="/exhibition-hall/publish"
              component={ExhibitionHallPublishPage}
            />
            <Route
                exact
                path="/marketplace/:tokenType/:tokenId"
                component={ExhibitionHallCardPage}
            />
          </div>
          </Switch>
          <InitPage/>
          <Footer />
          </Router>
        </Web3ReactProvider>
      </ContextProvider>
        )}
      </>

  );
}

export default App;
