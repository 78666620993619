import {useState, useEffect} from 'react';
import Gallery from '../../web3/abi/Gallery.json'
import FigureSwap from '../../web3/abi/FigureSwap.json'
import {getContract, useActiveWeb3React} from "../../web3";
import {
  getEnglishAuctionNFTAddress,
  getNFTTokenAddress
} from "../../web3/address";
import BigNumber from "bignumber.js";
import ERC721 from "../../web3/abi/ERC721.json";
import EnglishAuctionNFT from "../../web3/abi/EnglishAuctionNFT.json";
import {fetchApi} from "../../utils/fetchApi";

export const queryNFTInfo = async (library, address, id, chainId) =>{
  try {
    const contract = await getContract(library, ERC721.abi ,address);
    const symbol = await contract.methods.symbol().call()
    let tokenURl = await contract.methods.tokenURI(id).call()
    if (tokenURl) {
      try {
        const res = await fetch(tokenURl)
        const result = await res.json();
        if (result.image) {
          return  result.image
        } else if (result.properties.image.description) {
          return  result.properties.image.description
        }
      } catch (e) {
        console.log('fetch error', tokenURl, e)
      }
    }
  }catch (e){
    console.log('fetch nft error', e)
    return null
  }

}

export const useNFTList = () => {
  const {active, library, chainId} = useActiveWeb3React()
  const [nftList, seNFTList] = useState([])


  return {nftList}
}


export const useMyBiddenPool = () =>{
  const {active, account, library, chainId} = useActiveWeb3React()
  const [myBiddenPool, setMyBiddenPool] = useState([])

  async function queryMyBiddenPool (){
    const contract = getContract(library, EnglishAuctionNFT.abi, getEnglishAuctionNFTAddress(chainId))

    const count = await contract.methods.getMyBidCount(account).call()
    console.log('my bidden pool',count)
    if(count > 0){
      let pools = []
      for (let i=0; i<count; i++){
        const index = await contract.methods.myBidP(account, i).call()
        console.log('my bidden pool 1',index)

        const pool = await contract.methods.pools(index).call()

        pool.currentPrice = await contract.methods.currentBidderAmount(i).call()
        const bidder = await contract.methods.currentBidderP(i).call()

        pool.isWin = bidder.toLowerCase() === account.toLowerCase()

        const date = new Date(pool.closeAt * 1000)
        const now = new Date()
        pool.status = (date - now) > 0? 'live' :'closed'

        const poolInfo = await queryNFTInfo(library,pool.address, i, chainId)
        pool.title = poolInfo? poolInfo.title: ''
        pool.author = poolInfo && poolInfo.properties && poolInfo.properties.name && poolInfo.properties.name.description? poolInfo.properties.name.description: ''
        pool.image = poolInfo && poolInfo.properties && poolInfo.properties.image && poolInfo.properties.image.description? poolInfo.properties.image.description: ''
        pool.description = poolInfo && poolInfo.properties && poolInfo.properties.description && poolInfo.properties.description.description? poolInfo.properties.description.description: ''

        pools = pools.concat(pool)
        setMyBiddenPool(pools)
        console.log('pool:',pools)
      }
      // pool.claimed = await contract.methods.creatorClaimedP(index-1).call()
      // pool.claimed = await contract.methods.creatorClaimedP(index-1).call()
      // pool.currentBiddenAmount = await contract.methods.currentBidderAmount1P(index-1).call()

    }
  }

  useEffect(()=>{
    if(active){
      queryMyBiddenPool()
    }
  },[active])

  return {myBiddenPool}

}

export const usePools = (poolid, tokenId)=> {
  const {active, account ,library, chainId} = useActiveWeb3React()
  const [pools, setPools] = useState([])

  async function queryPools() {
    const contract = getContract(library, EnglishAuctionNFT.abi, getEnglishAuctionNFTAddress(chainId))

    contract.methods.getPoolCount().call().then( async count =>{
      let poolList = []
      for (let i = 0; i < count; i++) {
        const id = count - i - 1;
        const pool = await contract.methods.pools(id).call()
        if (poolid >= 0 && pool != poolid) {
            continue
        }
        if (tokenId > 0 && pool.tokenId != tokenId) {
            continue
        }
        pool.index = id
        pool.isMine = pool.creator.toLowerCase() === account.toLowerCase()
        pool.currentPrice = await contract.methods.currentBidderAmount(id).call()
        pool.creatorClaimedP = await contract.methods.creatorClaimedP(id).call()
        const curentBider = await contract.methods.currentBidderP(id).call()
        const currentBidderAmount1P = await contract.methods.currentBidderAmount1P(id).call()
        const bidder = await contract.methods.currentBidderP(id).call()
        pool.LastPrice = bidder != "0x0000000000000000000000000000000000000000" ? currentBidderAmount1P : pool.currentPrice
        if (pool.LastPrice <= 0) {
            pool.LastPrice = pool.amountMin1
        }
        const date = new Date(pool.closeAt * 1000)
        const now = new Date()
        pool.status = (date - now) > 0? 'live' :'closed'

        const bid = await contract.methods.myBidderAmount1P(account, id).call()
        pool.bid = bid > 0
        const poolInfo = await queryNFTInfo(library, pool.token0, pool.tokenId, chainId)
        pool.image = poolInfo ? poolInfo: ''

        poolList = poolList.concat(pool)
        setPools(poolList)
      }

    })
  }

  useEffect(()=>{
    if(active){
      queryPools()
    }
  },[active])

  return {pools}
}

export const useBiddenStatus = (index)=>{
  const {active, account, library, chainId} = useActiveWeb3React()
  const [bidden, setBidden] = useState(false)
  const [biddenAmount, setBiddenAmount] = useState(0)
  const [winner, setWinner] = useState('')
  const [claimed, setClaimed] = useState(true)



  function queryStatus(){
    try{
      const contract = getContract(library, EnglishAuctionNFT.abi, getEnglishAuctionNFTAddress(chainId))

      contract.methods.currentBidderP(index).call().then(res =>{
        setWinner(res)
      })


      contract.methods.myBidderAmount1P(account, index).call().then(res =>{

        contract.methods.currentBidderAmount(index).call().then(res1 =>{
            if(res > 0){
              setBidden(true)
              setBiddenAmount(res)
            } 
        })
      })

      contract.methods.myClaimedP(account, index).call().then(res =>{
        console.log('pool claim:',res)
        setClaimed(res)
      })

    }catch (e){

    }
  }

  useEffect(()=>{
    if(active){
      queryStatus()
    }
  },[active])

  return {bidden, biddenAmount, winner, claimed}
}


export const useCreatorStatus = (index, isMine, closed)=>{
  const {active, account, library, chainId} = useActiveWeb3React()
  const [hasWinner, setHasWinner] = useState(false)
  const [creatorClaimed, setCreatorClaimed] = useState(true)


  function queryStatus(){
    try{
      const contract = getContract(library, EnglishAuctionNFT.abi, getEnglishAuctionNFTAddress(chainId))

      contract.methods.currentBidderP(index).call().then(res =>{
        if(res !== '0x0000000000000000000000000000000000000000'){
          console.log('currentBidderP:',res)
          setHasWinner(true)
        }
      })

      contract.methods.creatorClaimedP(index).call().then(res =>{
        console.log('creatorClaimedP:',res)
        setCreatorClaimed(res)
      })

    }catch (e){
      console.log('useCreatorStatus',e)
    }
  }

  useEffect(()=>{
    if(active && isMine && closed){
      queryStatus()
    }
  },[active])

  return {hasWinner, creatorClaimed}
}
