import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Tabs } from 'antd';
import { useArtistInfo } from "./Hooks";
import { formatAddress } from "../../utils/format";
import { ArtistProfileModal } from "./ArtistProfileModal";
import {fetchApi} from '../../utils/fetchApi'
import { getTokenOwner } from "../useContract";
import { getContract, useActiveWeb3React } from "../../web3";
import {ReactComponent as Website} from '../../assets/img/artist/website.svg'
import {ReactComponent as Instagram} from '../../assets/img/artist/instagram.svg'
import {ReactComponent as Twitter} from '../../assets/img/artist/twitter.svg'
import { useTranslation } from "react-i18next";
import {
    ExhibitionHallCard
} from "../../components/ExhibitionHall";
import ERC721 from "../../web3/abi/ERC721.json";
import {getNFTTokenAddress} from "../../web3/address";

export const ArtistDetailPage = () => {
    const { TabPane } = Tabs;
    const [list, setList] = useState([]);
    const [collection, setCollection] = useState([]);
    const { address, tokenType, tokenId } = useParams();
    const { artistInfo } = useArtistInfo(address);
    const [profileModal, setProfileModal] = useState(false);
    const { t } = useTranslation()
    const {active, library, chainId, account} = useActiveWeb3React()


    const fetch = (address) => {
        fetchApi('tokens?creator=' + address, {}, library, chainId).then(({ tokens }) => {
            tokens = tokens.filter(token => token.owner === address)
            setList(tokens)
        })
    }

    const fetchCollection = () => {
        if (library) {
            const contract = getContract(library, ERC721.abi, getNFTTokenAddress(chainId))
            fetchApi('tokens', {}, library, chainId).then(async ({ tokens }) => {
                  tokens = await Promise.all(tokens.map(async item => {
                    const card = item
                    card.real_owner = await contract.methods.ownerOf(item.tokenId).call()
                    return card
                  }))
            console.log("tokens", tokens)
            setCollection(tokens)
        })}
    }

    useEffect(() => {
        fetch(address)
        fetchCollection()
    }, [active])

    return (
        <div className="artist">
            <ArtistProfileModal type="edit" artistInfo={artistInfo} visible={profileModal} closeModal={() => {setProfileModal(false)}}/>
            <div className="center">
                <div className="artist_detail">
                    <div className="info">
                        <div>
                            <div className="img"><img src={artistInfo.avatar}/></div>
                            <div className="name">
                                <h2>{artistInfo.name}</h2>
                                <div className="media">
                                    <div className="address">
                                        {formatAddress(address)}
                                    </div>   
                                </div>
                                <div className="media">
                                    <div>
                                        <a
                                            href={artistInfo.website}
                                            target="_blank"
                                        >
                                            <Website />
                                        </a>    
                                    </div> 
                                    <div>
                                        <a
                                            href={artistInfo.instagram}
                                            target="_blank"
                                        >
                                            <Instagram />
                                        </a>    
                                    </div>
                                    <div>
                                        <a
                                            href={artistInfo.twitter}
                                            target="_blank"
                                        >
                                            <Twitter />
                                        </a>    
                                    </div> 
                                </div>
                                <div className="media">
                                    {artistInfo.bio}
                                </div>
                            </div>
                        </div>
                        <div>
                            {/* <button
                                className="btn"
                                onClick={() => { setProfileModal(true) }}
                            >
                                <span>{t('edit')}</span>
                            </button> */}
                        </div> 
                    </div>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab={t('creations')} key="1">
                            <div className="exhibition-hall-list">
                            {list && list.filter(item => {return item.owner === address }).map(item => (
                                <ExhibitionHallCard key={item.tokenId} item={item}/>  
                            ))}
                            </div>
                        </TabPane>
                        <TabPane tab={t('collection')} key="2">
                            <div className="exhibition-hall-list">
                            {collection && collection.filter(item => {return item.real_owner === address }).map(item => (
                                <ExhibitionHallCard key={item.tokenId} item={item}/>  
                            ))}
                            </div>
                        </TabPane>
                        <TabPane tab={t('about')} key="3">
                            {artistInfo.aboutMe}
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};
