import React, { useState, useEffect } from "react";
import './index.css';
import { LandingPageHeader } from './LandingPageHeader'

export const LandingPage = (props) => {
    const handleLoadingState = () => {
        window.location.href = "http://de-fine.art";
        // props.setLoadingState(false);
    };

    const [artHovered, setArtHovered] = useState(false)
    const [gamesHovered, setGamesHovered] = useState(false)
    const [musicHovered, setMusicHovered] = useState(false)
    const [studioHovered, setStudioHovered] = useState(false)

    const updateHoveredCards = (cardName, func, value) => {
        cardName = value
        func(cardName)
    }



    return (
        <>
        <LandingPageHeader />
        <div className="loader-container">
            <div className="landing-rectangle"
            onMouseEnter={() => {
                updateHoveredCards(artHovered, setArtHovered, true)
            }}
            onMouseLeave={() => {
                updateHoveredCards(artHovered, setArtHovered, false)
            }}
            onClick={handleLoadingState}>
                <div className="logo-wrapper">
                    {!artHovered && <span className="textWrapper">Art</span>}
                    {artHovered && <div>
                        <div className="comingSoonTextWrapper">
                            <img className="logo" src={require('../../assets/img/landing-page/definelogo.png')} /></div>
                        <div className="enter-wrapper">
                            <img className="enterkey" src={require('../../assets/img/landing-page/enterkey.png')} />
                            <div className="enterKeyTextWrapper">
                                <span>Enter</span>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="landing-rectangle"
            onMouseEnter={() => {
                updateHoveredCards(gamesHovered, setGamesHovered, true)
            }}
            onMouseLeave={() => {
                updateHoveredCards(gamesHovered, setGamesHovered, false)
            }}>
                {!gamesHovered && <div className="logo-wrapper">
                    <span className="textWrapper">Game</span></div>}
                {gamesHovered && <div className="comingSoonTextWrapper">
                    <img className="gamelogo" src={require('../../assets/img/landing-page/definegame.png')} />
                    <div className="enter-wrapper">
                        <span>Coming Soon...</span>
                    </div>
                </div>}
            </div>
            <div className="landing-rectangle"
            onMouseEnter={() => {
                updateHoveredCards(musicHovered, setMusicHovered, true)
            }}
            onMouseLeave={() => {
                updateHoveredCards(musicHovered, setMusicHovered, false)
            }}>
                {!musicHovered && <div className="logo-wrapper">
                    <span className="textWrapper">Music</span></div>}
                {musicHovered && <div className="comingSoonTextWrapper">
                    <img className="gamelogo" src={require('../../assets/img/landing-page/definemusic.png')} />
                    <div className="enter-wrapper">
                        <span>Coming Soon...</span>
                    </div>
                </div>}
            </div>
            <div className="landing-rectangle"
            onMouseEnter={() => {
                updateHoveredCards(studioHovered, setStudioHovered, true)
            }}
            onMouseLeave={() => {
                updateHoveredCards(studioHovered, setStudioHovered, false)
            }}>
                {!studioHovered && <div className="logo-wrapper">
                    <span className="textWrapperDark">Studio</span>
                </div>}
                {studioHovered && <div className="comingSoonTextWrapper">
                    <img className="studiologo" src={require('../../assets/img/landing-page/definestudio.png')} />
                    <div className="enter-wrapper">
                        <span>Coming Soon...</span>
                    </div>
                </div>}
            </div>
            </div>
        </>
    )
}