import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import {getPoolLeftTime} from "../../utils/time";
import {formatAmount} from "../../utils/format";
import { useActiveWeb3React } from "../../web3";

export const ExhibitionHallCard = ({ item }) => {
    const href = `/marketplace/${item.tokenType}/${item.tokenId}`
    const { active } = useActiveWeb3React()
    const { t } = useTranslation();
    const [left, setLeft] = useState()
    let timer
    useEffect(() => {
        if (item.pool) {
            const date = new Date(item.pool.closedAt);
            const now = new Date();
            const lefttime = (date - now);
            if (lefttime > 0) {
                timer = setInterval(() => {
                    const l = getPoolLeftTime(item.pool.closeAt)
                    console.log('left:', l);
                    setLeft(l)
                }, 1000)   
            }
        }
        return () => {
            console.log("clear interval")
            clearInterval(timer)
        }
    }, [item.pool])
    
    return (
        <Card
            bordered={false}
            title={
                <div className="artist-info">
                    <img src={item.artistAvatar} />
                    <span>{item.artist}</span>
                </div>
            }
            // extra={<a href="#">More</a>}
        >
            <Link to={href}>
            <div className="image">
                <img src={item.image+"?t=1"} alt="`$`" width="280" height="280" />
            </div>
            <div className="content">
                <div className={`title ${active? 'active': ''}`}>
                    <span>{item.name}</span>
                </div>
                {
                    active && !item.pool && <div className="no-auction">Not Listed for Sale</div>        
                }    
                {active && item.pool &&
                        <div className={`auction-info ${item.pool.status}`}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        {item.pool.status === 'live' && item.pool.type === 'bid' && 'Current Price'}
                                        {item.pool.status === 'live' && item.pool.type === 'swap' && 'Price'}
                                        {item.pool.status === 'closed' && item.pool.type === 'bid' && item.pool.bidder && t('sold_for')}
                                        {item.pool.status === 'closed' && item.pool.type === 'bid' && !item.pool.bidder && 'Starting Price'}
                                        {item.pool.status === 'closed' && item.pool.type === 'swap' && t('sold_for')}
                                    </th>
                                    <th colspan="2">{left && `Ending In`}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{formatAmount(item.pool.LastPrice)}&nbsp;ETH</td>
                                    <td colspan="2">{left && `${left.days}d  ${left.hours} : ${left.minutes} : ${left.seconds}`}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
            </div>
            </Link>
        </Card>
    );
};
