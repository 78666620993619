import React, { useContext, useState, useRef, useEffect } from "react";
import {mainContext} from "../../reducer";
import {ReactComponent as LogoWhite} from '../../assets/img/logo-white.svg'
import { Radio, Select, Dropdown, Menu } from 'antd';
import {Link, NavLink, useLocation} from "react-router-dom";
import { CaretDownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import americaFlag from '../../assets/img/america.svg'
import koreaFlag from '../../assets/img/south-korea.svg'
import { CreateNFTModal } from "../../pages/ExhibitionHall/CreateNFTModal";
import './header.css';

const { Option } = Select;
export const LandingPageHeader = () => {
  const [showMenu, setShowMenu] = useState(false);
  const handleMenuItemClick = () => {
    setShowMenu(false);
  };
  const { t, i18n } = useTranslation();
  const changeLocale = () => {
    i18n.changeLanguage(i18n.language == 'en' ? 'ko' : 'en');} 
  const [createNFTModalVisible, setCreateNFTModalVisible] = useState(false);
  const {dispatch} = useContext(mainContext);

  return (
    <header>
    <div className="center">
        <div className="header__box">
            <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                <div className="header__logo">
                <img className="logo" src={require('../../assets/img/landing-page/define.png')} />
                </div>
                <CaretDownOutlined />
            </div>
        <div className="header__menu-wrapper">
                <div className="header__menu">
                    <nav className="menu">
                        <ul className="menu__list">
                            <li className="menu__item">
                                <NavLink
                                    to="/about"
                                    className="menu__link"
                                    id="aboutTextWrapper"
                                    activeClassName="is-current"
                                    onClick={handleMenuItemClick}
                                >
                                    About
                                </NavLink>
                            </li>
                            <li className="menu__item">
                                <Select
                                  value={i18n.language == 'en' ? 'en' : 'ko'}
                                  onChange={changeLocale}
                                  showArrow={false}
                                  bordered={false}
                                >
                                    <Option key="en" value='en'>
                                        <img src={americaFlag} style={
                                            { width: '30px',
                                             borderRadius: '50%'}
                                        } />
                                    </Option>
                                    <Option key="ko" value='ko'>
                                        <img src={koreaFlag} style={
                                            {width: '30px',
                                             borderRadius: '50%'}
                                        }/>
                                    </Option>
                                </Select>
                            </li>    
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
      </div>
    </header>
  );
};
