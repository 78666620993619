import React, {useState, useEffect} from "react";
import InfiniteScroll from 'react-infinite-scroller';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { ArtistCard } from "./ArtistCard";
import { useArtistList } from "./Hooks";

export const ArtistListPage = () => {
    const { t } = useTranslation();
    const [hasMore, setHasMore] = useState(true);
    const [current, setCurrent] = useState(1);
    const { artistList, total } = useArtistList(current, 10);

    const handleInfiniteOnLoad = () => { 
        if (artistList.length >= total) { 
            setHasMore(false);
            return
        }
        if (current < total / 10) { 
            setCurrent(current + 1);
        }
    }
    return (
        <div className="artist">
            <h1>{t('creator')}</h1>
            <hr/>
            <InfiniteScroll
                initialLoad={true}
                pageStart={0}
                loadMore={handleInfiniteOnLoad}
                hasMore={hasMore}
                className="artist_list"
                loader={
                    <div className="loading">
                        <Spin />
                    </div>
                }
            >
                {artistList.map(item => (
                    <ArtistCard
                        key={item.tokenId}
                        item={item}
                    />
                ))}
            </InfiniteScroll>
        </div>
    );
};
