import { useState, useEffect } from 'react';
import env from '../../constants/env';

export const fetchArtistList = async (pageNo, pageSize) =>{
  try {
    const res = await fetch(`${env.API_URL}/artists?pageNo=${pageNo}&pageSize=${pageSize}`);
    const result = await res.json();
    return  result
  } catch (e) {
    console.log('fetch artist error')
  }
}

export const useArtistList = (pageNo, pageSize) => {
  const [artistList, seArtistList] = useState([])
  const [total, setTotal] = useState(1)

  async function queryArtistList() {
    try {
      const data = await fetchArtistList(pageNo, pageSize);
      seArtistList(artistList.concat(data.artists));
      setTotal(data.totalCount)
    } catch (e) {
      console.log('query artist list error', e)
    }
  }

  useEffect(()=>{
    queryArtistList()
  }, [pageNo, pageSize])

  return {artistList, total}
}

export const fetchArtistInfo = async (address) =>{
  try {
    const res = await fetch(`${env.API_URL}/artists/${address}`);
    const result = await res.json();
    return  result
  } catch (e) {
    console.log('fetch artist error')
  }
}

export const useArtistInfo = (address) => {
  const [artistInfo, seArtistInfo] = useState({})
  async function queryArtistInfo() {
    try {
      const data = await fetchArtistInfo(address);
      seArtistInfo(data);
    } catch (e) {
      console.log('query artist list error', e)
    }
  }
  useEffect(()=>{
    queryArtistInfo()
  }, [])

  return {artistInfo}
}
