// English Auction NFT
export function getEnglishAuctionNFTAddress(chainId) {
    switch (chainId) {
        case 1:
            return  '0xaE2C96d0643180aEd6dc16192b6E1a0113b156cF'
        default:
            return  '0xaE2C96d0643180aEd6dc16192b6E1a0113b156cF'
    }
}

// GLF staking
export function getGLFStakingAddress(chainId) {
    switch (chainId) {
        case 1:
            return  '0x47fd85128312ee72aa0e0382a531a8f848b8b2cb'
        case 4:
            return  '0xD7AD78B0B839cBE9B0f9bE0b10250779553a4411'
        case 31337:
            return  '0xa4bcDF64Cdd5451b6ac3743B414124A6299B65FF'
        default:
            return '0x47fd85128312ee72aa0e0382a531a8f848b8b2cb'
    }
}

// BOT
export function getBotStakingAddress(chainId) {
    switch (chainId) {
        case 1:
            return  '0x75e0Ebd7CD13A9e2450bdf8736Eb8e4Cee5f01F7'
        case 4:
            return  '0xF19b7F8F22b3e1a45040c208BdC82B246d246E85'

        default:
            return  '0xaE2C96d0643180aEd6dc16192b6E1a0113b156cF'
    }
}


export function getNFTAddress(chainId) {
    switch (chainId) {
        case 1:
            return  'https://etherscan.io/address/0x70A76282752b5D2F09f81fe86D49d80ED8B53DC7'
        case 4:
            return  'https://rinkeby.etherscan.io/address/0x3B790F79875c7262860Dc4bb4Bbed2b7D29811cB'
        default:
            return 'https://etherscan.io/address/0x4B5Ab4805353F698e3099163c51872881578D81A'
    }
}



export function getUSDTTokenAddress() {
    return '0xdac17f958d2ee523a2206206994597c13d831ec7'
}

export function getNFT721Address(chainId) {
    switch (chainId) {
        case 1:
            return '0x70A76282752b5D2F09f81fe86D49d80ED8B53DC7'
        default:
            return '0x70A76282752b5D2F09f81fe86D49d80ED8B53DC7'
    }
}

export function getNFT1155Address(chainId) {
    switch (chainId) {
        case 1:
            return '0x70A76282752b5D2F09f81fe86D49d80ED8B53DC7'
        default:
            return '0x70A76282752b5D2F09f81fe86D49d80ED8B53DC7'
    }
}

export function getChainType(chainId) {
    switch (chainId) {
        case 1:
            return 'mainnet'
        case 4:
            return 'rinkeby'
        case 56:
            return 'bsc'
        case 97:
            return 'testnet'
        default:
            return 'mainnet'
    }
}

export function getNFTIndexerAddress(chainId) {
    switch (chainId) {
        case 1:
            return '0x639636207a5db7322d007c9035ea0f29edc21b82'
        default:
            return '0x639636207a5db7322d007c9035ea0f29edc21b82'
    }
}

export function getNFTTokenAddress(chainId) {
    return getNFT721Address(chainId)
}

export function getTokenContracts() {
    return { "0x70A76282752b5D2F09f81fe86D49d80ED8B53DC7": "DFA"}
}
