import React, { useState, useContext } from "react";
import { Modal, Button, Radio, Form, Input, DatePicker } from 'antd';
import Web3 from "web3";
import { CrossModalIcon } from "../../icons";
import { getContract, useActiveWeb3React } from "../../web3";
import ERC721 from '../../web3/abi/ERC721.json'
import { getEnglishAuctionNFTAddress, getNFT721Address, getNFT1155Address } from "../../web3/address";
import EnglishAuctionNFT from "../../web3/abi/EnglishAuctionNFT.json";
import {
    HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
    HANDLE_SHOW_TRANSACTION_MODAL,
    HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
    waitingForConfirm,
    waitingForInit,
    waitingPending
} from "../../const";
import {mainContext} from "../../reducer";
import { getTime } from "../../utils/time";

const {toWei} = Web3.utils


const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
export const SellNFTModal = ({ visible, closeModal, auctionInfo, token }) => { 
    const {account, library, chainId} = useActiveWeb3React()
    const {dispatch} = useContext(mainContext);
    const [type, setType] = useState('bid');

  
    const handleCancel = () => {
      closeModal();
    };

    const onFinish = async (values) => {
        console.log('Success:', values);
        const address = token.tokenType === "ERC721" ? getNFT721Address(chainId) : getNFT1155Address(chainId);
        const tokenContract = getContract(library, ERC721.abi, address)
        const contract = getContract(library, EnglishAuctionNFT.abi, getEnglishAuctionNFTAddress(chainId))
        const weiPrice = toWei(values.price, 'ether');
        const weiIncr = toWei(values.incr, 'ether');
        dispatch({
            type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
            showWaitingWalletConfirmModal: waitingForConfirm
        });
        try {
            await tokenContract.methods.approve(
                getEnglishAuctionNFTAddress(chainId),
                token.tokenId,
            ).send({from: account});
            console.log('approve  success')

            dispatch({
                type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                showWaitingWalletConfirmModal: waitingForConfirm
            });

            // let time
            const time = getTime(values.time.days, values.time.hours, values.time.minutes);
            console.log('data:',token.name, address, token.tokenId,weiPrice, 0.1,time)
            await contract.methods.createErc721(
                "Auction " + token.tokenId,
                address,
                "0x0000000000000000000000000000000000000000",
                token.tokenId,
                weiPrice,
                weiIncr,
                time, false)
                .send({from: account})
                .on('transactionHash', hash => {
                    dispatch({
                        type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                        showWaitingWalletConfirmModal: {...waitingPending, hash}
                    });
                })
                .on('receipt', (_, receipt) => {
                    dispatch({
                        type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                        showWaitingWalletConfirmModal: {...waitingForInit, link: '/marketplace/' + token.tokenId}
                    });
                    dispatch({
                        type: HANDLE_SHOW_TRANSACTION_MODAL,
                        showTransactionModal: true
                    });
                })
                .on('error', (err, receipt) => {
                    dispatch({
                        type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
                        showFailedTransactionModal: true
                    });
                    dispatch({
                        type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                        showWaitingWalletConfirmModal: waitingForInit
                    });
                })

        } catch (err) {
            dispatch({
                type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
                showWaitingWalletConfirmModal: waitingForInit
            });
            if (err.code === 4001) {
                dispatch({
                    type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
                    showFailedTransactionModal: true
                });
            } else {
                dispatch({
                    type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
                    showFailedTransactionModal: true
                });
            }
            console.log('err', err);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleTypeChange = e => { 
        setType(e.target.value);
    }


  
    return (
        <Modal
            title="Sell the NFT"
            visible={visible}
            onCancel={handleCancel}
            footer={null}
            width={500}
            className="sell-nft-modal"
            closeIcon={<CrossModalIcon />}
        >
            <Form
                name="sell-nft"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                requiredMark={false}
                {...layout}
            >
                <Form.Item
                    label="NFT Name"
                    name="name"
                    initialValue={token && token.name }
                    rules={[{ required: true, message: 'Please input NFT Name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Quantity to Sell"
                    name="quantity"
                    initialValue={auctionInfo && auctionInfo.tokenAmount0 }
                    rules={[{ required: true, message: 'Please input Quantity to Sell!' }]}
                >
                    <Input addonAfter={`/${auctionInfo && auctionInfo.tokenAmount0}`}/>
                </Form.Item>
                <Form.Item
                    label="Select your sell method"
                    name="type"
                    initialValue={type}
                    rules={[{ required: true, message: 'Please input Quantity to Sell!' }]}
                >
                    <Radio.Group className="type" onChange={handleTypeChange}>
                        <Radio.Button value="swap">Fixswap</Radio.Button>
                        <Radio.Button value="bid">Auction</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                {type === 'swap' &&
                    <Form.Item
                        label="Price"
                        name="price"
                        rules={[{ required: true, message: 'Please input Quantity to Sell!' }]}
                    >
                        <Input addonAfter="ETH"/>
                    </Form.Item>
                }
                {type === 'bid' &&
                    <>
                        <Form.Item
                            label="Starting Price"
                            name="price"
                            rules={[{ required: true, message: 'Please input Quantity to Sell!' }]}
                        >
                            <Input addonAfter="ETH"/>
                        </Form.Item>
                        <Form.Item
                            label="Mid bid"
                            name="incr"
                            rules={[{ required: true, message: 'Please input Quantity to Sell!' }]}
                        >
                            <Input addonAfter="ETH"/>
                        </Form.Item>
                        {/* <Form.Item
                            label="Expiration date"
                            name="date"
                            rules={[{ required: true, message: 'Please input Quantity to Sell!' }]}
                        >
                            <DatePicker />
                        </Form.Item> */}
                        <Form.Item label="Expiration date">
                            <Input.Group compact>
                                <Form.Item
                                    name={['time', 'days']}
                                    noStyle
                                    rules={[{ required: true, message: 'Days is required' }]}
                                >
                                    <Input style={{ width: '33%' }} placeholder="Days" />
                                </Form.Item>
                                <Form.Item
                                    name={['time', 'hours']}
                                    noStyle
                                    rules={[{ required: true, message: 'Hours is required' }]}
                                >
                                    <Input style={{ width: '33%' }} placeholder="Hours" />
                                </Form.Item>
                                <Form.Item
                                    name={['time', 'minutes']}
                                    noStyle
                                    rules={[{ required: true, message: 'Minutes is required' }]}
                                >
                                    <Input style={{ width: '33%' }} placeholder="Minutes" />
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>    
                    </>
                }
                <Form.Item label="Fee (2.5%)">
                    <Input value="2.5%" />
                </Form.Item> 
                <p>The handling charge will be deducted after the transaction.</p>
                <div style={{textAlign: 'center', marginTop: '30px'}}>
                    <Button type="primary" htmlType="submit">
                        Confirm to Sell
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}
