import { useState, useEffect } from 'react';
import env from '../../constants/env';
import { getAuctionPool, get721AuctionPool } from "../useContract";

export const fetchTokensList = async (pageNo, pageSize, chainType) =>{
  try {
    const res = await fetch(`${env.API_URL}/tokens?chainType=${chainType}&pageNo=${pageNo}&pageSize=${pageSize}`);
    const result = await res.json();
    return  result
  } catch (e) {
    console.log('fetch artist error')
  }
}

const fetchPool = async (tokenId, tokenType, library, chainId, account) => {
    if (tokenType === 'ERC721' && library) {
        const poolid = await get721AuctionPool(tokenId, library, chainId)
        const AuctionPool = await getAuctionPool(poolid, tokenId, account, library, chainId)
        console.log('AuctionPool:', AuctionPool);
        return AuctionPool
    } else {
        return undefined
    }
}

export const useTokensList = (pageNo, pageSize, chainType, library, chainId, account) => {
  const [tokensList, setTokensList] = useState([])
  const [total, setTotal] = useState(1)

  async function queryTokensList() {

    try {
        const data = await fetchTokensList(pageNo, pageSize, chainType);
        if (data.tokens && data.tokens.length > 0) {
            data.tokens.forEach(async (token, index) => {
                data.tokens[index]['pool'] = await fetchPool(token.tokenId, token.tokenType, library, chainId, account);
            })
        }
        setTokensList(tokensList.concat(data.tokens));
        setTotal(data.totalCount)
    } catch (e) {
        console.log('query tokens list error', e)
    }
  }

  useEffect(()=>{
    queryTokensList()
  }, [pageNo, pageSize, chainType])

  return { tokensList, total }
}

export const fetchTokenInfo = async (chainType, tokenType, tokenId) =>{
  try {
    const res = await fetch(`${env.API_URL}/tokens/${chainType}/${tokenType}/${tokenId}`);
    const result = await res.json();
    return  result
  } catch (e) {
    console.log('fetch token info error')
  }
}

export const useTokenInfo = (address) => {
  const [tokenInfo, seTokenInfo] = useState({})
  async function queryTokenInfo() {
    try {
      const data = await fetchTokenInfo(address);
      seTokenInfo(data);
    } catch (e) {
      console.log('query token info error', e)
    }
  }
  useEffect(()=>{
    queryTokenInfo()
  }, [])

  return {tokenInfo}
}
