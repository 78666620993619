import {useState, useEffect} from 'react';
import StakingRewardsV2 from '../web3/abi/StakingRewardsV2.json'
import ERC20 from '../web3/abi/ERC20.json'
import {getContract, useActiveWeb3React} from "../web3";
import Web3 from "web3";


export const useBalance = (address) =>{
    const {account, active, library, chainId} = useActiveWeb3React()
    const [ balance, setBalance] = useState()

    useEffect(()=>{
        if(active){
            if(!address){
                const web3 = new Web3(library.provider);
                  web3.eth.getBalance(account).then(res => {
                      console.log('eth balance:',res)
                      console.log('chain id is', chainId)
                      setBalance(res)
                  })
            }else {
                try{
                    const contract = getContract(library, ERC20.abi, address)
                    console.log('token totalSupply:',address)
                    contract.methods.balanceOf(account).call().then(res =>{
                        console.log('token totalSupply:',res)
                        setBalance(res)
                    })
                }catch (e) {
                    console.log('load token balance error:',e)

                }
            }
        }
    },[active])

    return {balance}
}
