import React from "react";
import { Link } from "react-router-dom";
import titleBefore from "../assets/img/home/title.png";
import feature1 from "../assets/img/home/feature1.png";
import feature2 from "../assets/img/home/feature2.png";
import feature3 from "../assets/img/home/feature3.png";
import feature4 from "../assets/img/home/feature4.png";
import feature5 from "../assets/img/home/feature5.png";
import feature6 from "../assets/img/home/feature6.png";
import pdf from '../assets/Define Art White Pape-R.pdf';
import Component from '../assets/img/Component.png';
import { useTranslation } from 'react-i18next';


export const AboutPage = () => {
    const { t } = useTranslation();
    return (
        <div className="wrapper__container">
            <div>
                <aside className="billboard">
                    <div className="center">
                        <div className="billboard__box">
                            <div className="billboard__content">
                                <h1 className="billboard__title h1">
                                    <span className="text-uppercase h1-main">
                                    {t('the_first_decentralized_nft_platform_in_korea_1')}
                                    <br />
                                    {t('the_first_decentralized_nft_platform_in_korea_2')}
                                    </span>
                                    <br />
                                </h1>
                            </div>
                            <div className="btn-box">
                                <a className="btn" href={pdf} download>
                                    {t('whitepaper')}
                                </a>
                            </div>
                        </div>
                    </div>
                </aside>
                <aside className="home-service">
                    <h2 className="title"><img src={titleBefore}/>{t('our_features')}</h2>
                    <div className="center">
                        <ul className="home-service__list">
                            <li className="home-service__item">
                                <img src={feature1}/>
                                <h2 className="home-service__title h4">
                                {t('initial_nft_offering')}
                                </h2>
                                <p className="home-service__text">
                                {t('ino_detail_text')}
                                </p>
                            </li>
                            <li className="home-service__item">
                                <img src={feature2}/>
                                <h2 className="home-service__title h4">
                                {t('nft_auction_house')}
                                </h2>
                                <p className="home-service__text">
                                {t('nft_auction_text')}
                                </p>
                            </li>
                            <li className="home-service__item">
                                <img src={feature3}/>
                                <h2 className="home-service__title h4">
                                {t('nft_exhibition_hall')}
                                </h2>
                                <p className="home-service__text">
                                    {t('nft_exhibition_text')}
                                </p>
                            </li>
                            <li className="home-service__item">
                                <img src={feature4}/>
                                <h2 className="home-service__title h4">
                                {t('express_mint')}
                                </h2>
                                <p className="home-service__text">
                                    {t('express_mint_txt')}
                                </p>
                            </li>
                            <li className="home-service__item">
                                <img src={feature5}/>
                                <h2 className="home-service__title h4">
                                {t('nft_and_defi_combination')}
                                </h2>
                                <p className="home-service__text">
                                {t('nft_and_defi_combination_text')}
                                </p>
                            </li>
                            <li className="home-service__item">
                                <img src={feature6}/>
                                <h2 className="home-service__title h4">
                                {t('nft_index_fund')}
                                </h2>
                                <p className="home-service__text">
                                {t('nft_index_fund_text')}
                                </p>
                            </li>
                        
                        </ul>
                    </div>
                </aside>
                <aside className="home-cards">
                    <h2 className="title"><img src={titleBefore} />{t('multi_chain_architecture')}</h2>
                    <p><img src={Component}/></p>
                </aside>
                <aside className="home-cards">
                    <h2 className="title"><img src={titleBefore}/>{t('dfa_the_first_nft_community_governance_token_in_korea')}</h2>
                    <div className="center">
                        <div className="home-cards__box">
                            <div className="home-cards__item home-cards__token">
                                <h2 className="h3"> {t('currency_for_nft_space')} </h2>
                                <p>
                                {t('currency_for_nft_space_text')}
                                </p>
                            </div>
                            <div className="home-cards__item home-cards__token">
                                <h2 className="h3">{t('define_art_dao')} </h2>
                                <p>
                                {t('define_art_dao_text')} 
                                </p>
                            </div>
                            <div className="home-cards__item home-cards__token">
                                <h2 className="h3"> {t('activity_mining')}</h2>
                                <p>
                                {t('activity_mining_Text')}
                                </p>
                            </div>
                            <div className="home-cards__item home-cards__token">
                                <h2 className="h3">{t('airdrops')}</h2>
                                <p>
                                {t('airdrops_text')} 
                                </p>
                            </div>
                            <div className="home-cards__item home-cards__token">
                                <h2 className="h3">{t('defi_collateral')}</h2>
                                <p>
                                {t('defi_collateral_text')}  
                                </p>
                            </div>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
    );
}
