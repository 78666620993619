import React, { useContext, useState, useRef, useEffect } from "react";
import {Link, NavLink, useLocation} from "react-router-dom";
import { Radio, Select, Dropdown, Menu } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {useActiveWeb3React, getContract} from "../../web3";
import {formatAddress, formatAmount} from "../../utils/format";
import {mainContext} from "../../reducer";
import {HANDLE_SHOW_CONNECT_MODAL} from "../../const";
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { useToggle } from '../../hooks/useToggle'
import { getNFTTokenAddress } from "../../web3/address";
import DAF721NFT from "../../web3/abi/DFA721NFT.json";

import {Logoicon, LogoSmallIcon} from "../../icons";
import {ReactComponent as LogoBlack} from '../../assets/img/logo-black.svg'
import {ReactComponent as LogoWhite} from '../../assets/img/logo-white.svg'
import {ReactComponent as LogoColor} from '../../assets/img/logo-color.svg'
import {ReactComponent as ETHIcon} from '../../assets/img/ETH.svg'
import americaFlag from '../../assets/img/america.svg'
import koreaFlag from '../../assets/img/south-korea.svg'
import BSCIcon from '../../assets/img/bsc.png'
import {useBalance, useGLFBalance} from "../../pages/Hooks";
import { CreateNFTModal } from "../../pages/ExhibitionHall/CreateNFTModal";

const { Option } = Select;

export const Header = () => {
    const {active, chainId, library, account} = useActiveWeb3React();
    const {dispatch} = useContext(mainContext);
    const { t, i18n } = useTranslation();

    const [showMenu, setShowMenu] = useState(false);
    const [wallet, setWallet] = useState('eth');
    const [createNFTModalVisible, setCreateNFTModalVisible] = useState(false);
    const {balance} = useBalance();
    console.log(balance)
    const location = useLocation();
    const ref = useRef()
    const [open, toggle] = useToggle(false)
    const [isArtist, setIsArtist] = useState(false);
    useOnClickOutside(ref, open ? toggle : undefined)
    let symbol = "ETH"
    if (chainId == 56 || chainId == 97) {
        symbol = "BNB"
        if (wallet != "bsc")
            setWallet("bsc")
    } else if (chainId == 1 || chainId == 4){
        if (wallet != "eth")
            setWallet("eth");
    }

    const getIsArtist = async () => {
        const nftAddress = getNFTTokenAddress(chainId)
        const nft = getContract(library, DAF721NFT.abi, nftAddress)
        console.log(nft, nftAddress)
        const isArtist = await nft.methods.artist(account).call()
        console.log('isArtist:', isArtist);
        setIsArtist(isArtist);
    }

    const changeLocale = () => {
        i18n.changeLanguage(i18n.language == 'en' ? 'ko' : 'en');
    }

    const handleMenuItemClick = () => {
        setShowMenu(false);
    };

    const scrollHeader = () => {
        if (window.location.pathname !== "/about") {
            return;
        }
        // 获取当前滚动的高度
        var scrollTop = document.documentElement.scrollTop;
        console.log('scrollTop:', scrollTop);
        if (scrollTop >= 60) {
            document.querySelector('.header__box').style.background = '#fff';
            document.querySelector('.menu__list').style.color = '#000';
        } else {
            document.querySelector('.header__box').style.background = 'transparent';
            document.querySelector('.menu__list').style.color = '#fff';
        }
    }

    const handleWalletChange = e => {
        setWallet(e.target.value);
    };

    useEffect(() => {
        if (active) {
            getIsArtist();
        }
    }, [active])

    useEffect(() => {
        window.addEventListener('scroll', scrollHeader, false);
        return () => {
            window.removeEventListener('scroll', scrollHeader, false);
        }
    }, [])

    const menu = (
        <Menu className="header-dropdown">
          <Menu.Item>
            <Link to="/coming-soon">
              {t('game')}
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/coming-soon">
            {t('music')}
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/coming-soon">
            {t('studio')}
            </Link>
          </Menu.Item>
        </Menu>
      );

    return (
        <header
            className={`header ${showMenu ? "menu-show" : ""}`}
            style={location.pathname === "/about" || location.pathname === "/coming-soon" ? {borderBottom: "transparent", color: '#fff'} : {background: '#fff'}}
        >
            <div className="center">
                <div className="header__box">
                        <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <div className="header__logo">
                                <LogoBlack />
                            </div>
                            {/* <CaretDownOutlined /> */}
                        </div>

                    <div className="header__menu-wrapper">
                        <div className="header__menu">
                            <nav className="menu">
                                <ul className="menu__list" style={location.pathname === "/about" || location.pathname === "/coming-soon" ? {color:'#fff'} : {color: '#000'}}>
                                    <li className="menu__item">
                                        <NavLink
                                            to="/"
                                            className={`menu__link ${location.pathname === '/'? 'is-current' : ''}`}
                                            onClick={handleMenuItemClick}
                                        >
                                            {t('home')}
                                        </NavLink>
                                    </li>
                                    <li className="menu__item">
                                        <NavLink
                                            to="/marketplace"
                                            className="menu__link"
                                            activeClassName="is-current"
                                            onClick={handleMenuItemClick}
                                        >
                                            {t('marketplace')}
                                        </NavLink>
                                    </li>
                                    <li className="menu__item">
                                        <NavLink
                                            to="/creators"
                                            className="menu__link"
                                            activeClassName="is-current"
                                            onClick={handleMenuItemClick}
                                        >
                                            {t('creator')}
                                        </NavLink>
                                    </li>
                                    {/* <li className="menu__item" >
                                        <a className="menu__link" onClick={() => {setCreateNFTModalVisible(true)}}>Create</a>
                                    </li> */}
                                    <li className="menu__item">
                                        <NavLink
                                            to="/about"
                                            className="menu__link"
                                            activeClassName="is-current"
                                            onClick={handleMenuItemClick}
                                        >
                                            {t('about')}
                                        </NavLink>
                                    </li>
                                    <li className="menu__item">
                                        <a
                                            href="https://docs.de-fine.art/"
                                            className="menu__link"
                                            target="_blank"
                                        >
                                            {t('how_it_works')}
                                        </a>
                                    </li>
                                    {/*<li className="menu__item">
                                        <Radio.Group className="wallet" value={wallet} onChange={handleWalletChange}>
                                            <Radio.Button value="eth"><ETHIcon /></Radio.Button>
                                            <Radio.Button value="bsc"><img src={BSCIcon} /></Radio.Button>
                                        </Radio.Group>
                                        </li>*/}
                                    <li className="menu__item">
                                        <Select value={i18n.language == 'en' ? 'en' : 'ko'} onChange={changeLocale}>
                                            <Option key="en" value='en'>
                                                <img src={americaFlag} width='16px'/>EN
                                            </Option>
                                            <Option key="ko" value='ko'>
                                                <img src={koreaFlag} width='16px' />한국어
                                            </Option>
                                        </Select>
                                    </li>    
                                </ul>
                            </nav>
                        </div>
                        <CreateNFTModal visible={createNFTModalVisible} closeModal={() => {setCreateNFTModalVisible(false)}}/>
                        {!active && (
                            <div className="header__btn">
                                <button
                                    onClick={() => {
                                        dispatch({
                                            type: HANDLE_SHOW_CONNECT_MODAL,
                                            showConnectModal: true
                                        });
                                    }}
                                    className="btn"
                                    type="button"
                                    data-modal="recieve"
                                >
                                    {t('connect_wallet')}
                                </button>
                            </div>
                        )}

                        {active && (
                            <div className="header-account" ref={ref}>
                                <div className="address" onClick={toggle}>
                                    {balance && formatAmount(balance)} {symbol}
                                    <div className="point"></div>
                                </div>
                                {open && 
                                    <ul className="dropdown">
                                        <li className="active"><a href="JavaScript:void(0);">{formatAddress(account)}</a></li>
                                        {isArtist && <li><Link to="/exhibition-hall/publish">Create</Link></li>}
                                        {/*
                                        <li><a href="JavaScript:void(0);">{t('my_profile')}</a></li>
                                        <li><a href="JavaScript:void(0);">{t('my_pools')}</a></li>
                                        <li><a href="JavaScript:void(0);">{t('logout')}</a></li>
                                        */
                                        }
                                    </ul>
                                }
                            </div>
                        )}
                    </div>
                </div>

                <div className="visible-md">
                    <button
                        className="button btn-menu-toggle"
                        type="button"
                        onClick={() => setShowMenu(prev => !prev)}
                    >
                        Menu
                    </button>
                </div>
            </div>
        </header>
    );
};
