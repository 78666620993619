import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfiniteScroll from 'react-infinite-scroller';
import { Spin } from 'antd';

import {
    ExhibitionHallCard,
    ExhibitionHallSlider
} from "../../components/ExhibitionHall";
import DAF721NFT from "../../web3/abi/DFA721NFT.json";
import {fetchApi} from '../../utils/fetchApi'
import { getContract, useActiveWeb3React } from "../../web3";
import { getNFTTokenAddress, getChainType } from "../../web3/address";
import { BackButton } from "../../components/BackButton";
import { NFTNoticeModal } from '../../components/Modals/NFTNoticeModal';
import { usePools } from "../Auction/Hooks";
import { getAuctionPool, get721AuctionPool } from "../useContract";
import { useTokensList } from "./Hooks";

export const ExhibitionHallListPage = ({isMine}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [isArtist, setIsArtist] = useState(false)
    const [hasMore, setHasMore] = useState(true);
    const [current, setCurrent] = useState(1);
    const {active, library, chainId, account} = useActiveWeb3React()
    const chainType = getChainType(chainId)
    const {tokensList, total} = useTokensList(current, 10, chainType, library, chainId, account)
    const { t } = useTranslation()
    console.log('total:', total);
    const getIsArtist = async () => {
        const nftAddress = getNFTTokenAddress(chainId)
        const nft = getContract(library, DAF721NFT.abi, nftAddress)
        console.log(nft, nftAddress)
        const isArtist = await nft.methods.artist(account).call() 
        console.log('isArtist:', isArtist);
        setIsArtist(isArtist);
    }

    const handleInfiniteOnLoad = () => { 
        if (tokensList.length >= total) { 
            setHasMore(false);
            return
        }
        if (current < total / 10) { 
            setCurrent(current + 1);
        }
    }


    useEffect(() => {
        if (active) {
            getIsArtist();   
        }
    }, [active])


    return (
        <div className="home">
            <article className="exhibition-hall center">

                {modalOpen &&
                    <div className='modal-show'>
                        <div className='wrapper'>
                            <NFTNoticeModal setModalOpen={() => {setModalOpen(false)}}/>
                        </div>
                    </div>
                }
                <header className="exhibition-hall-head">
                    <h1>{t('marketplace')}
                    </h1>
                    <hr/>

                </header>
                {/* <div className="auction-button_group">
                    <button className={`btn ${isArtist ? '': 'disabled'}`} onClick={() => {
                            if (isArtist) {
                                history.push('/exhibition-hall/publish')
                            } else {
                                setModalOpen(true)
                            }
                    }}>Publish an artwork
                    </button>
                    <a className="btn" onClick={()=>{
                        history.push('/exhibition-hall-mine')
                    }}>My NFT</a>
                </div> */}
            </article>
            <InfiniteScroll
                initialLoad={true}
                pageStart={0}
                loadMore={handleInfiniteOnLoad}
                hasMore={hasMore}
                className="exhibition-hall-list"
                loader={
                    <div className="loading">
                        <Spin />
                    </div>
                }
            >
                {tokensList && tokensList.map(item => (
                    <ExhibitionHallCard
                        key={item.tokenId}
                        item={item}
                    />
                ))}
                </InfiniteScroll>    
        </div>
    );
};
