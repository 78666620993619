import ERC721 from "../web3/abi/ERC721.json";
import ERC1155 from "../web3/abi/ERC1155.json";
import EnglishAuctionNFT from "../web3/abi/EnglishAuctionNFT.json";
import FixedSwapNFT from "../web3/abi/FixedSwapNFT.json";
import NFTIndexer from "../web3/abi/NFTIndexer.json"
import {getContract} from "../web3";
import {getNFTTokenAddress, getNFT721Address, getNFT1155Address, getEnglishAuctionNFTAddress, getNFTIndexerAddress} from "../web3/address";

const ERC721Contract = (library, chainId) => {
  return getContract(library, ERC721.abi, getNFT721Address(chainId))
}

const ERC1155Contract = (library, chainId) => {
  return getContract(library, ERC1155.abi, getNFT1155Address(chainId))
}

const EnglishAuctionNFTContract = (library, chainId) => {
  return getContract(library, EnglishAuctionNFT.abi, getEnglishAuctionNFTAddress(chainId))
} 

const FixedSwapNFTContract = (library, chainId) => {
  return getContract(library, FixedSwapNFT.abi, getNFTTokenAddress(chainId))
}

const NFTIndexerContract = (library, chainId) => {
  return getContract(library, NFTIndexer.abi, getNFTIndexerAddress(chainId))
}

export const createNFT = (type, address, amount, data, library, chainId) => {
  if (type === 'ERC721') {
    return new Promise((resolve, reject) => {
      ERC721Contract(library, chainId).methods.mint()
      .send({from: address})
      .on('receipt', (receipt) => {
        if (!receipt.events.Mint || !receipt.events.returnValues) {
          reject(receipt)
        }
        resolve(receipt)
      }).on('error', (error, receipt) => {
        reject(receipt)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      ERC1155Contract(library, chainId).methods.mint(amount, data)
      .send({from: address})
      .on('receipt', (receipt) => {
        if (!receipt.events.Mint || !receipt.events.returnValues) {
          reject(receipt)
        }
        resolve(receipt)
      }).on('error', (error, receipt) => {
        reject(receipt)
      })
    })
  }
}

export const sellNFT = (type, address, parameters, library, chainId) => {
  if (type === 'ERC721') {
    return new Promise((resolve, reject) => {
      FixedSwapNFTContract(library, chainId).methods.createErc721(
        parameters.name,
        parameters.token0,
        parameters.token1,
        parameters.tokenId,
        parameters.amountTotal1,
        parameters.duration)
      .send({from: address})
      .on('receipt', (receipt) => {
        resolve(receipt)
      }).on('error', (error, receipt) => {
        reject(receipt)
      })  
    })
  } else {
    return new Promise((resolve, reject) => {
      FixedSwapNFTContract(library, chainId).methods.createErc1155(
        parameters.name,
        parameters.token0,
        parameters.token1,
        parameters.tokenId,
        parameters.amountTotal0,
        parameters.amountTotal1,
        parameters.duration)
      .send({from: address})
      .on('receipt', (receipt) => {
        resolve(receipt)
      }).on('error', (error, receipt) => {
        reject(receipt)
      })  
    })
  }
}

export const creatorClaimBeforeEnd = (index, address, library, chainId) => {
  return new Promise((resolve, reject) => {
    FixedSwapNFTContract(library, chainId).methods.creatorClaim(index)
    .send({from: address})
    .on('receipt', (receipt) => {
      resolve(receipt)
    }).on('error', (error, receipt) => {
    reject(receipt)})
  })
}

export const creatorClaimAfterEnd = (index, address, library, chainId) => {
  return new Promise((resolve, reject) => {
    EnglishAuctionNFTContract(library, chainId).methods._creatorClaim(index)
    .send({from: address})
    .on('receipt', (receipt) => {
      resolve(receipt)
    }).on('error', (error, receipt) => {
    reject(receipt)})
  })
}

export const fixedBuyNFT = (index, amount, address, library, chainId) => {
  return new Promise((resolve, reject) => {
    FixedSwapNFTContract(library, chainId).methods.swap(index, amount)
    .send({from: address})
    .on('receipt', (receipt) => {
      resolve(receipt)
  }).on('error', (error, receipt) => {
    reject(receipt)})
  })
}

export const bid = (address, index, bidAmount, library, chainId) => {
  return new Promise((resolve, reject) => {
    FixedSwapNFTContract(library, chainId).methods.swap(index, bidAmount)
    .send({from: address, value: bidAmount})
    .on('receipt', (receipt) => {
      resolve(receipt)
  }).on('error', (error, receipt) => {
    reject(receipt)})
  })
}

export const bidderClaim = (address, index, library, chainId) => {
  return new Promise((resolve, reject) => {
    EnglishAuctionNFTContract(library, chainId).methods.bidderClaim(index)
    .send({from: address})
    .on('receipt', (receipt) => {
      resolve(receipt)
  }).on('error', (error, receipt) => {
    reject(receipt)})
  })
}

export const get721AuctionPool = async (tokenId, library, chainId) => { //get 721 auction pool id
  return await NFTIndexerContract(library, chainId).methods.get721Auction(getNFT721Address(chainId), tokenId).call()
}

export const get1155AuctionPool = async (token_owner_address, tokenId, chainId, library) => { //get 1155 auction pool id
  return await NFTIndexerContract(library, chainId).methods.get1155Auction(getNFT1155Address(chainId), token_owner_address, tokenId).call()
}

export const get721SwapPool = async (tokenId, library, chainId) => {//get 721 swap pool id
  return await NFTIndexerContract(library, chainId).methods.get721Fixswap(getNFT721Address(chainId), tokenId).call()
}

export const get1155SwapPool = async (token_owner_address, tokenId, chainId, library) => {//get 1155 swap pool id
  return await NFTIndexerContract(library, chainId).methods.get1155Fixswap(getNFT1155Address(chainId), token_owner_address, tokenId).call()
}

export const getAuctionPool = async (poolid, tokenId, address, library, chainId) => {
  const contract = EnglishAuctionNFTContract(library, chainId)
  const pool = await contract.methods.pools(poolid).call()
  if (tokenId < 0 || pool.tokenId != tokenId) {
    return
  }
  pool.index = poolid
  pool.isMine = pool.creator.toLowerCase() === address.toLowerCase()
  pool.currentPrice = await contract.methods.currentBidderAmount(poolid).call()
  pool.creatorClaimedP = await contract.methods.creatorClaimedP(poolid).call()
  const currentBider = await contract.methods.currentBidderP(poolid).call()
  const currentBidderAmount1P = await contract.methods.currentBidderAmount1P(poolid).call()
  const bidder = await contract.methods.currentBidderP(poolid).call()
  pool.LastPrice = bidder !== "0x0000000000000000000000000000000000000000" ? currentBidderAmount1P : pool.currentPrice
  if (pool.LastPrice <= 0) {
    pool.LastPrice = pool.amountMin1
  }
  const date = new Date(pool.closeAt * 1000)
  const now = new Date()
  pool.type = 'bid'
  pool.bidder = bidder !== "0x0000000000000000000000000000000000000000"
  pool.status = (date - now) > 0? 'live' :'closed'
  const bid = await contract.methods.myBidderAmount1P(address, poolid).call()
  pool.bid = bid > 0
    //const poolInfo = await queryNFTInfo(library, pool.token0, pool.tokenId, chainId)
    //pool.image = poolInfo ? poolInfo: ''
  return pool
}

export const getSwapPool = async (poolid, tokenId, address, library, chainId) => {
  const contract = FixedSwapNFTContract(library, getNFTTokenAddress(chainId))
  const pool = await contract.methods.pools(poolid).call()
  if (poolid <= 0 && pool === poolid) {
    return
  }
  if (tokenId < 0 && pool.tokenId === tokenId) {
    return
  }
  pool.index = poolid
  pool.isMine = pool.creator.toLowerCase() === address.toLowerCase()
  pool.currentPrice = await contract.methods.currentBidderAmount(poolid).call()
  pool.creatorClaimedP = await contract.methods.creatorClaimedP(poolid).call()
  const currentBider = await contract.methods.currentBidderP(poolid).call()
  const currentBidderAmount1P = await contract.methods.currentBidderAmount1P(poolid).call()
  const bidder = await contract.methods.currentBidderP(poolid).call()
  pool.LastPrice = bidder !== "0x0000000000000000000000000000000000000000" ? currentBidderAmount1P : pool.currentPrice
  if (pool.LastPrice <= 0) {
    pool.LastPrice = pool.amountMin1
  }
  pool.type = 'swap'
  const date = new Date(pool.closeAt * 1000)
  const now = new Date()
  pool.status = (date - now) > 0? 'live' :'closed'
  const bid = await contract.methods.myBidderAmount1P(address, poolid).call()
  pool.bid = bid > 0
    //const poolInfo = await queryNFTInfo(library, pool.token0, pool.tokenId, chainId)
    //pool.image = poolInfo ? poolInfo: ''
  return pool
}

export const usePools = (poolid, tokenId, address, library, chainId) => {
  const contract = EnglishAuctionNFTContract(library, getEnglishAuctionNFTAddress(chainId))
  return new Promise((resolve) => {
    let poolList = []
    contract.methods.getPoolCount().call().then(async count =>{
    for (let i = 0; i < count; i++) {
      const id = count - i - 1;
      const pool = await contract.methods.pools(id).call()
      if (poolid >= 0 && pool !== poolid) {
          continue
      }
      if (tokenId > 0 && pool.tokenId !== tokenId) {
          continue
      }
      pool.index = id
      pool.isMine = pool.creator.toLowerCase() === address.toLowerCase()
      pool.currentPrice = await contract.methods.currentBidderAmount(id).call()
      pool.creatorClaimedP = await contract.methods.creatorClaimedP(id).call()
      const currentBider = await contract.methods.currentBidderP(id).call()
      const currentBidderAmount1P = await contract.methods.currentBidderAmount1P(id).call()
      const bidder = await contract.methods.currentBidderP(id).call()
      console.log(bidder)
      pool.LastPrice = bidder !== "0x0000000000000000000000000000000000000000" ? currentBidderAmount1P : pool.currentPrice
      if (pool.LastPrice <= 0) {
          pool.LastPrice = pool.amountMin1
      }
      const date = new Date(pool.closeAt * 1000)
      const now = new Date()
      pool.status = (date - now) > 0? 'live' :'closed'

      const bid = await contract.methods.myBidderAmount1P(address, id).call()
      pool.bid = bid > 0
        //const poolInfo = await queryNFTInfo(library, pool.token0, pool.tokenId, chainId)
        //pool.image = poolInfo ? poolInfo: ''

      poolList = poolList.concat(pool)
    }
  })
  resolve(poolList)
})
}

const queryNFTInfo = async (address, id, library, chainId) =>{
  try {
    const symbol = await ERC721Contract(library, chainId).methods.symbol().call()
    let tokenURl = await ERC721Contract(library, chainId).methods.tokenURI(id).call()
    if (tokenURl) {
      try {
        if(tokenURl.indexOf('de-fine.art')){
          tokenURl = `https://api.de-fine.art/v2/tokens/${id}`
        }
        const res = await fetch(tokenURl)
        const result = await res.json();
        if (result.image) {
          return  result.image
        } else if (result.properties.image.description) {
          return  result.properties.image.description
        }
      } catch (e) {
        console.log('fetch error', tokenURl, e)
      }
    }
  } catch (e){
    console.log('fetch nft error', e)
    return null
  }
}

export const getTokenOwner = (library, chainId, tokenId) => {
  return new Promise((resolve, reject) => {
    let tokenList = ERC721Contract(library, getNFTTokenAddress(chainId)).ownerOf(tokenId).call()
    resolve(tokenList)
  })
}

export const getTokenBalanceBeforeAuction = (library, chainId, address, tokenId) => {
  return new Promise((resolve, reject) => {
    let balance = ERC1155Contract(library, getNFTTokenAddress(chainId)).balanceOf(address, tokenId).call()
    resolve(balance)
  })
}
