import React, { useContext, useState } from "react";
import BigNumber from "bignumber.js";
import {getContract, useActiveWeb3React} from "../../web3";
import ERC20 from "../../web3/abi/ERC20.json";
import Minter from "../../web3/abi/Minter.json";
import Web3 from "web3";
import {promisify} from '../../utils/promisify';
import { useTranslation } from 'react-i18next';

import {fetchApi} from '../../utils/fetchApi'

import { useHistory } from "react-router-dom";
import {mainContext} from "../../reducer";
import { BackButton } from "../../components/BackButton";
import { CropImageModal } from "../../components/Modals";
import { getNFTTokenAddress } from "../../web3/address";
import {ConnectWalletModal} from '../../components/Modals/ConnectWalletModal';

import {
    HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
    HANDLE_SHOW_TRANSACTION_MODAL,
    HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
    waitingForConfirm,
    waitingForInit,
    waitingPending
} from "../../const";

function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
}

class TokenPublisher {
  constructor(onChange){
    this.onChange = onChange
    this.metadata = {
      count: 1
    }
    this.errors = {
      name: "",
      artist: "",
      hashtags: "",
      count: "",
    };

    this.onSubmit = this.onSubmit.bind(this)
    this.openImage = this.openImage.bind(this)
    this.setCropModalOpen = this.setCropModalOpen.bind(this)
    this.setCroppedImage = this.setCroppedImage.bind(this)
  }

  isMainnet(){
    return this.chainId === 1 || this.chainId === 4
  }

  showWalletConfirmModal(options){
    this.dispatch({
        type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
        showWaitingWalletConfirmModal: options || waitingForConfirm
    });
  }

  closeWalletConfirmModal(){
    this.dispatch({
        type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
        showWaitingWalletConfirmModal: {show: false}
    });
  }

  showTxConfirmModal(hash){
    this.dispatch({
        type: HANDLE_SHOW_WAITING_WALLET_CONFIRM_MODAL,
        showWaitingWalletConfirmModal: {...waitingPending, hash}
    });
  }

  showErrorModal(){
    this.dispatch({
        type: HANDLE_SHOW_FAILED_TRANSACTION_MODAL,
        showFailedTransactionModal: true
    });
  }

  openImage = (ev) => {
    const files = ev.target.files
    const _this = this;
    console.log('files:', files);
    if (files && files.length !== 0 ) {
      this.file = files[0]
      document.getElementById("videoBox").innerHTML = '';
      if (files[0].type === "image/png" || files[0].type === 'image/png'
        || files[0].type === 'image/jpg' || files[0].type === 'image/jpeg'
      ) {
          this.setCropModalOpen(true)
          this.image = URL.createObjectURL(this.file)
      }
      if (files[0].type === 'image/gif') {
        if (window.FileReader) {
          const fr = new FileReader();
          fr.onloadend = function (e) {
            const img = document.createElement("img");
            img.src = e.target.result;
            img.width = 380;
            img.height = 380;
            document.getElementById("videoBox").appendChild(img);
            _this.croppedImage = e.target.result;
          };
          fr.readAsDataURL(files[0]);
        }
      }
      if (files[0].type === 'video/mp4' || files[0].type === "video/quicktime") {
        if (window.FileReader) {
          const fr = new FileReader();
          fr.onloadend = function (e) {
              const video = document.createElement("video");
              video.controls = "controls";
              video.src = e.target.result;
              video.width = 380;
              video.height = 380;
            document.getElementById("videoBox").appendChild(video);
            _this.croppedImage = e.target.result;
          };
          fr.readAsDataURL(files[0]);
        }
      }
      /*
      var reader = new FileReader();
      reader.onload = () => {
        this.image = reader.result;
        this.onChange()
      }
      reader.readAsDataURL(this.file);
      */
    }
  }


  setWeb3(web3React){
    console.log('web3React', web3React)
    if(web3React && web3React.library){
      this.account = web3React.account
      this.library = web3React.library
      this.web3 = new Web3(this.library.provider);
      this.chainId = web3React.chainId
      this.nftAddress = getNFTTokenAddress(this.chainId)
      this.minterContract = getContract(this.library, Minter.abi, this.nftAddress)
    }
  }

  setDispath(dispatch){
    this.dispatch = dispatch
  }

  setHistory(history){
    this.history = history
  }

  setMetadata(name, value){
    const { t } = useTranslation()
    console.log('setting metadata', name, value)
    this.metadata[name] = value
    this.errors = {
        name: "",
        artist: "",
        hashtags: "",
        count: ""
    };
    switch(name){
      case 'name':
        this.errors.name =
            value.length < 1 ? t('name_of_the_artwork_can_not_be_empty') : "";
        break;
      case 'count':
        this.errors.count =
            value < 1 ? t('number_of_the_copies_can_not_less') : "";
        break;
      case 'artist':
        this.errors.artist =
            value.length < 1 ? t('artist_name_cannot_be_empty') : "";
        break;
    }
    //this.errors.hashtags = value.length < 1 ? "Hashtags cannot be empty" : "";


    this.onChange()
  }

  isValid(){
    return this.croppedImage != null && this.errors.name.length == 0 &&
           this.errors.artist.length == 0
  }


  dispose(){
  }

  onSubmit(){
    console.log('onSubmit')
    this.publish()
  }

  async publish(){

    try {



      this.showWalletConfirmModal()
      await this.minterContract.methods.mint()
          .send({from: this.account})
          .on('transactionHash', (hash) => {
            this.showTxConfirmModal(hash)
          })
          .on('receipt', (receipt) => {
            var events = receipt.events
            if(events.Mint && events.Mint.returnValues){
              var tokenId = events.Mint.returnValues.tokenId
              this.uploadMetadata(tokenId, 'erc721', this.chainId, receipt)
            } else {
              // TODO provide error message
              // Error: token was not issued
              this.showErrorModal()
            }
          })
          .on('error', (err, receipt) => {
            this.showErrorModal()
          })

    } catch (e) {
      console.log('e', e)
      // TODO: provide error message e.message
      this.showErrorModal()
    }


  }

  setCropModalOpen(isOpen){
    this.cropModalOpen = isOpen
    this.onChange()
  }

  setCroppedImage(image){
    this.croppedImage = image
    this.onChange()
  }

  async uploadMetadata(tokenId, tokenType, chainId, receipt){
    console.log('uploading meta for token Id', tokenId)

    this.showWalletConfirmModal({show: true, title: 'Uploading metadata'})


    var params = {...this.metadata}
    params.tokenId = tokenId
    params.owner = this.account
    params.txHash = receipt.transactionHash

    var formData = new FormData();


    for(var k in params){
      console.log('append', k)
      formData.append(k,params[k]);
    }

    formData.append('chainId', chainId)
    formData.append('tokenType', tokenType)
    formData.append('image', this.file)
    formData.append('preview', dataURLtoBlob(this.croppedImage))

    var resp = await fetchApi('tokens', {
      method: 'POST',
      body: formData,
    }, this.library, this.chainId)
    this.closeWalletConfirmModal()
    this.history.push("/marketplace/ERC721/" + tokenId)
  }
}


const ExhibitionHallPublishPageView = ({publisher}) => {

    let web3React = useActiveWeb3React();
    publisher.setWeb3(web3React)

    const {dispatch} = useContext(mainContext);
    publisher.setDispath(dispatch)

    let history = useHistory()
    publisher.setHistory(history)

    const handleChange = event => {
        event.preventDefault();
        const { name, value } = event.target;
        publisher.setMetadata(name,value)
    };

    var errors = publisher.errors
    var {name,hashtags,description,artist,count} = publisher.metadata
    const { t } = useTranslation();

  return (
      <>
        {/* <article className="center publish">
          {!publisher.isMainnet() &&
            <div className='modal-show'>
              <div className='wrapper'>
                <ConnectWalletModal />
              </div>
            </div>
          }
        </article> */}
      {(publisher.isMainnet()) &&
        <form action="/" className="publish-artwork" onSubmit={publisher.onSubmit}>
          <div className="publish-artwork__content">
            <div className="">
              <div className="publish-artwork__drop publish-artwork__inputbox">
                <input
                    className="publish-artwork__drop-input"
                    type="file"
                    accept="image/png, image/jpg, image/jpeg, video/mp4, video/quicktime, image/gif"
                    onChange={publisher.openImage}
                />
                <div className="publish-artwork__drop-content">
                    {publisher.croppedImage ? (
                        <img className="cover" src={publisher.croppedImage} />
                    ) : (
                        <p className="publish-artwork__drop-text">
                        {t('click_to_upload_or_drag_file_here')}
                        </p>
                    )}
                </div>
                <div id="videoBox" className="videoBox">
                </div>
              </div>
              <p className="publish-artwork__drop-format">
                {t('supports_jpg_jpeg2000')}
              </p>
            </div>
              <div className="publish-artwork__form">
                <div className="publish-artwork__row">
                  <div className="publish-artwork__row-item publish-artwork__inputbox">
                    <label
                        htmlFor="name"
                        className="publish-artwork__label"
                    >
                        {t('artwork_name')}
                    </label>
                    <input
                        className={`publish-artwork__input input ${
                            errors.name.length > 0 && "input--error"
                        }`}
                        placeholder={t('artwork_name')}
                        name="name"
                        value={name}
                        onChange={handleChange}
                        required
                    />
                    {errors.name.length > 0 && (
                        <div className="publish-artwork__text-error">
                            {t('name_of_the_artwork_can_not_be_empty')}
                        </div>
                    )}
                  </div>
                </div>
                <div className="publish-artwork__textarea publish-artwork__inputbox">
                    <label
                        htmlFor="publish-artwork-textarea"
                        className="publish-artwork__label"
                    >
                        {t('details')}
                    </label>
                    <textarea
                        className="publish-artwork__input input"
                        id="publish-artwork-textarea"
                        placeholder={t('no_more_than_200_chars')}
                        name="description"
                        value={description}
                        onChange={handleChange}
                    />
                  </div>
                <div className="publish-artwork__row">
                <div className="publish-artwork__row-item publish-artwork__inputbox">
                    <label
                      htmlFor="count"
                      className="publish-artwork__label"
                    >
                        {t('number_of_copies')}
                    </label>
                      <input
                          className={`publish-artwork__input input ${
                              errors.count.length > 0 && "input--error"
                          }`}
                          placeholder={t('number_of_copies')}
                          name="count"
                          value={count}
                          onChange={handleChange}
                          required
                      />
                      {errors.count.length > 0 && (
                          <div className="publish-artwork__text-error">
                              {t('number_of_the_copies_can_not_less')}
                          </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
              <button
                  className="publish-artwork__btn btn"
                  type="button"
                  onClick={publisher.onSubmit}
                  disabled={!publisher.isValid()}
              >
                  {t('publish')}
              </button>
          </form>
          }
          {publisher.isMainnet() && publisher.cropModalOpen && (
            <div className="modal-show">
                <div className="wrapper">
                    <CropImageModal
                        setCropModalOpen={publisher.setCropModalOpen}
                        tempImage={publisher.image}
                        setCroppedImage={publisher.setCroppedImage}
                    />
                </div>
            </div>
        )}
      </>
    );
};

export class ExhibitionHallPublishPage extends React.Component {
  
  componentWillMount(){
    this.publisher = new TokenPublisher(() => this.forceUpdate())
  }

  componentWillUnmount(){
    this.publisher.dispose()
  }

  render(){
    return <ExhibitionHallPublishPageView publisher={this.publisher}/>
  }
}

