import React from "react";
import { Modal, Button, Space } from 'antd';
import { ExhibitionHallPublishPage} from "./ExhibitionHallPublishPage";
export const CreateNFTModal = ({ visible, closeModal }) => { 
    console.log('visible: ', visible);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
  
    const handleOk = () => {
      setConfirmLoading(true);
      setTimeout(() => {
        closeModal();
        setConfirmLoading(false);
      }, 2000);
    };
  
    const handleCancel = () => {
      closeModal();
    };
  
    return (
        <Modal
          title="Create New Item"
          visible={visible}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
          footer={null}
          width={600}
        >
            <ExhibitionHallPublishPage/>
        </Modal>
    )
}
