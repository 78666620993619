import React, { useContext } from "react";
import Lottie from "react-lottie";
import loading from '../../assets/loading.json'
import { CrossModalIcon } from "../../icons";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const NFTNoticeModal = ({setModalOpen}) => {

    return (
        <div className="modal">
            <div className="modal__box">
                <div className="form-app">
                    <div className="form-app__inner transction-submitted">
                        <p style={{textAlign: 'left'}}>
                            Currently, we only support featured creators to publish their artworks. If you would like to join us, please submit your application contact@de-fine.art.
                        </p>
                        <button
                            type="button"
                            className="form-app__close-btn button"
                            onClick={() => setModalOpen(false)}
                        >
                            <CrossModalIcon />
                        </button>

                    </div>
                </div>
            </div>
        </div>
    );
};
